function sidebar() {
  var element = document.getElementById("hambtn");
  if(element)
    element.classList.toggle("active");

  var element = document.getElementById("aside");
  if(element)
    element.classList.toggle("active");

  var element = document.getElementById("aside");
  if(element)
    element.classList.toggle("closed");

  var element = document.getElementById("walletDrop");
  if(element)
    element.classList.remove("show");

  var element = document.getElementById("orderDrop");
  if(element)
    element.classList.remove("show");

  var element = document.getElementById("walletDropM");
  if(element)
    element.classList.remove("show");

  var element = document.getElementById("orderDropM");
  if(element)
    element.classList.remove("show");

}


function forgotPassword() {
  var element = document.getElementById("loginSection");
  if(element)
    element.classList.add("forgotPassword");
}

function signIn() {
  var element = document.getElementById("loginSection");
  if(element)
    element.classList.remove("forgotPassword");
}


function myFunction(x) {
  if (x.matches) { // If media query matches
    var element = document.getElementById("aside");
    if(element)
      element.classList.add("closed");

    var element = document.getElementById("aside");
    if(element)
      element.classList.remove("active");
  } 
  
  else {
    var element = document.getElementById("aside");
    if(element)
      element.classList.remove("closed");

    var element = document.getElementById("aside");
    if(element)
      element.classList.add("active");
  }
}

var x = window.matchMedia("(max-width: 992px)")
myFunction(x) // Call listener function at run time
x.addListener(myFunction) // Attach listener function on state changes